import { useCallback, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import useUser from "@/common/hook/user/useUser";
import request from "@/utils/request";
import AuthTokenStore from "@/common/store/AuthTokenStore";

function useLogin(router) {
  const { t } = useTranslation("auth");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { mutate } = useUser(router.query.redirectTo || "/dashboard", true);

  const login = useCallback(
    async ({ email: username, password }) => {
      if (loading) {
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await request.post("/login_check", { username, password });
        AuthTokenStore.set(response.data.token);
        mutate();
      } catch (e) {
        setError(e.response?.status === 401 ? t`incorrect-password` : e.message);
      } finally {
        setLoading(false);
      }
    },
    [t, loading, setError, mutate]
  );

  return [loading, error, login];
}

export default useLogin;
