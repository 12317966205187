
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import { useForm } from "react-hook-form";
import Image from "next/image";
import { NextSeo } from "next-seo";
import Layout from "@/components/core/Layout";
import Input from "@/components/common/form/Input";
import InputPassword from "@/components/common/form/InputPassword";
import MiniConsultantCard from "@/components/common/MiniConsultantCard";
import useLogin from "@/common/hook/auth/useLogin";
import Alert from "@/components/common/Alert";

// consultantName
// consultantImage
// consultantTariff
const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation("auth");
  const router = useRouter();
  const [loading, error, login] = useLogin(router);

  return (
    <Layout className="flex" hasFooter={false}>
      <NextSeo title={t`auth:title.login`} />
      <div className="mx-auto lg:py-28">
        <div className="relative lg:w-[582px]">
          <Image
            src="/login/background.png"
            alt="Background Image"
            layout="fill"
            placeholder="blur"
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII="
            className="!hidden lg:!block"
          />
          <div className="relative py-12 px-4 lg:px-12">
            <div className="mb-12 text-center font-title text-3xl">{t`common:login`}</div>
            <MiniConsultantCard />
            {router.query.tokenExpired && (
              <Alert type="info" className="mb-6">
                {t`token-expired`}
              </Alert>
            )}
            <form onSubmit={handleSubmit(login)} className="flex w-full flex-col items-start">
              {error && <Alert>{error}</Alert>}
              <Input
                label={t`common:email.label`}
                id="email"
                placeholder={t`common:email.placeholder`}
                register={register}
                rules={{ required: true, pattern: /^\S+@\S+$/i }}
                autoComplete="email"
              >
                {errors.email &&
                  (errors.email.type === "required" ? t`common:error.required` : t`common:error.valid-email`)}
              </Input>
              <InputPassword
                id="password"
                label={t`common:password.label`}
                register={register}
                rules={{ required: true, minLength: 8 }}
                placeholder={t`common:password.placeholder`}
                className="mt-6"
                autoComplete="current-password"
              >
                {errors.password &&
                  (errors.password.type === "required"
                    ? t`common:error.required`
                    : t("common:error.min-length", { length: 8 }))}
              </InputPassword>
              <button type="submit" className="button hover-orange mt-12 w-full self-end text-lg" disabled={loading}>
                {loading ? <div className="spinner" /> : t`button.login`}
              </button>
            </form>
            <div className="mt-12 text-sm text-grey-800">
              <Trans
                i18nKey="auth:redirect.reset-password"
                components={{
                  redirect: (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    <button
                      type="button"
                      className="font-semibold text-orange hover:text-orange-900"
                      onClick={() => router.push("/reset-password")}
                    />
                  ),
                }}
              />
            </div>
            <div className="text-sm text-grey-800">
              <Trans
                i18nKey="auth:redirect.register"
                components={{
                  redirect: (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    <button
                      type="button"
                      className="font-semibold text-orange hover:text-orange-900"
                      onClick={() =>
                        router.push(
                          router.query.redirectTo ? { pathname: "/register", query: router.query } : "/register"
                        )
                      }
                    />
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/login',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  