import Image from "next/image";
import clsx from "clsx";

const TYPE_TO_CLASS = {
  danger: "bg-red-900 text-red",
  info: "bg-blue-800 text-blue",
  success: "bg-green-800 text-green",
};

const Alert = ({ type = "danger", children, className, margin = true }) => (
  <div
    className={clsx(
      " flex w-full items-center space-x-3 rounded-md p-4",
      TYPE_TO_CLASS[type],
      margin && "mb-4",
      className
    )}
  >
    <div className="flex shrink-0">
      <Image src={`/alert/${type}.svg`} alt="Alert" width={20} height={20} />
    </div>
    <span className="whitespace-pre-line text-sm">{children}</span>
  </div>
);

export default Alert;
