import { useRouter } from "next/router";
import { useMemo } from "react";
import useTranslation from "next-translate/useTranslation";
import Avatar from "@/components/common/Avatar";

const MiniConsultantCard = () => {
  const { t } = useTranslation("auth");
  const router = useRouter();
  const isValidConsultant = useMemo(() => router.query.consultantName && router.query.consultantImage, [router.query]);

  if (!isValidConsultant) {
    return null;
  }

  const { consultantName, consultantImage, type } = router.query;

  return (
    <div className="mb-6 flex items-center rounded-lg bg-grey-100 py-2 px-4">
      <Avatar
        author={consultantName}
        image={`${process.env.NEXT_PUBLIC_STORAGE_URL}/consultants/${consultantImage}`}
        className="mr-3 h-12 w-12 shrink-0"
      />
      <div>
        {t(`mini-consultant-card-text.${type === "follow" ? "follow" : "contact"}`, { consultant: consultantName })}
      </div>
    </div>
  );
};
export default MiniConsultantCard;
